import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "import-audit-data" }, [_c("div", { staticClass: "mx-5 mt-5" }, [_c("a-card", [_c("h2", { staticClass: "import-title mb-3" }, [_vm._v("Import Audit Data")]), _c("div", { staticClass: "mb-2" }, [_c("h4", [_vm._v(" All files are required to be located in \\\\saahasfp800\\Groups\\Payroll\\Transfer as follows: ")]), _vm._v("1. DatapaySalary.csv "), _c("br"), _vm._v("2. DatapayWaged.csv "), _c("br"), _vm._v("3. WorkdayData.xlsx "), _c("br"), _vm._v("4. Datapayextract.csv ")]), _c("h4", [_vm._v(" Use the button below to start the import process and finalise Audit Report data. "), _c("br"), _vm._v("This process updates both Datapay to Kronos and Datapay to Workday audits. ")]), _c("a-divider"), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading }, on: { "click": _vm.onImportClick } }, [_vm._v("Import")])], 1)], 1)])], 1);
};
var staticRenderFns$1 = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ImportAuditData",
  data() {
    return {
      apiUrl,
      isLoading: false,
      itemsMenu: [
        {
          key: "payroll",
          title: "Payroll",
          path: ""
        },
        {
          key: "import-audit-data",
          title: "Import Audit Data",
          path: "/web-analytics/import-audit-data"
        }
      ]
    };
  },
  methods: {
    onImportClick() {
      this.$confirm({
        class: "list__modal-confirm",
        title: "Are you sure you want to import the listed files?",
        cancelText: "Cancel",
        okText: "OK",
        okType: "primary",
        onOk: this.onConfirmImport()
      });
    },
    onConfirmImport() {
      return () => {
        this.isLoading = true;
        this.axios.post(`${this.apiUrl}/web-analytics/payroll/import-audit-data`).then((res) => {
          this.$notification["success"]({
            message: res.data.message
          });
          this.isLoading = false;
        }).catch((err) => {
          this.$notification.error({
            message: err.response.data.message || "Something went wrong, please try again"
          });
          this.isLoading = false;
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ImportAuditData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("import-audit-data");
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    ImportAuditData
  },
  data() {
    return {
      ImportAuditData
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
